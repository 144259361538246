.footerSect{
    margin-top: 5rem;
    background: #F4F3F3;
}

.h1Footer{
    color: #6C0505;
    text-align: center;
    font-family: Merriweather;
    font-size: 54px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 60px 0px 2px 0;
}

.quick{
    color: #6C0505;
    text-align: center;
    font-family: Merriweather;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.quickLinks{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    list-style: none;
    padding: 5px 0 70px 0;
}

li a {
    text-decoration: none;
    color: #6F0909;
    font-family: Merriweather;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    padding: 10px;
    line-height: normal;
}


@media only screen and (max-width: 767px) {
    .quickLinks{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding: 5px 0 60px 0;
    }
    li{
        margin-bottom: 0.3rem;
    }
    li a{
        font-size: 20px;
        padding: 10px;
    }
}

@media only screen and (min-width: 767px) and (max-width: 991px) {
    .quickLinks{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 5px 0 60px 0;
    }
    li a{
        padding: 1px 0 0 16px;
        margin-bottom: 0.3rem
    };
}