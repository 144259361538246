#alert{
    width: 60%;
}

@media only screen and (max-width: 767px) {
    #alert{
        width: 100%;
        display: block;
        margin: auto;
    }
}

@media only screen and (min-width: 767px) and (max-width: 1198px){
    #alert{
        width: 60%;
        display: block;
        margin: auto;
    }
}